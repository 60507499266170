import { usePermissions } from "common/Permissions";
import { DateTime } from "luxon";
import _ from "lodash";
import { useGetUsersInGroup } from "./connectors/userConnectors";

export const dateFromISO = (field) => {
  const retVal = field && DateTime.fromISO(field ?? "");
  return (retVal?.isValid && retVal) || null;
};

export const dateFromCustomFormat = (field, format) => {
  const retVal = field && DateTime.fromFormat(field ?? "", format);
  return (retVal?.isValid && retVal) || null;
};

export const getDateTimeFormat = (field) => (item) => {
  const date = dateFromISO(item?.[field]);
  return date?.toUTC().toString() || "";
};

export const getDateTimeDisplayFormat = (field) => (item) => {
  const date = dateFromISO(item?.[field]);
  return date?.toLocaleString(DateTime.DATETIME_MED) || "";
};

export const getDateFormat = (field) => (item) => {
  const date = dateFromCustomFormat(item?.[field], "MM/dd/yyyy");
  return date?.toUTC().toString() || "";
};

export const getDateDisplayFormat = (field) => (item) => {
  const date = dateFromCustomFormat(item?.[field], "MM/dd/yyyy");
  return date?.toLocaleString(DateTime.DATE_MED) || "";
};

export const formatUser = (user) => {
  if (!user?.firstName && !user?.lastName) {
    return null;
  }
  return `${user?.firstName} ${user?.lastName}`;
};

export const getUserFormat = (field) => (item) => {
  if (item?.[field] === "Null" || item?.[field] === null) return "Not Set";
  return item?.[field] !== null
    ? `${item?.[field]?.firstName} ${item?.[field]?.lastName} ${
        !item?.[field]?.firstName && !item?.[field]?.lastName
          ? item?.[field]?.email
          : ""
      } ${item?.[field]?.inactive === true ? `(inactive)` : ""}`
    : "Unknown User";
};

export const getUserFormatById = (field) => (item) => {
  const { getOrganizationUser } = usePermissions();
  return (
    getOrganizationUser(item?.[field])?.displayName ||
    (item[field] === "Null" ? "Not Set" : item?.[field] || "Unknown User")
  );
};

export const getActivityFeedUserFormat = (field) => (item) => {
  const { getOrganizationUser } = usePermissions();
  const userData = getOrganizationUser(item?.[field]);
  return `${userData?.user?.firstName} ${userData?.user?.lastName} ${
    !userData?.user?.firstName && !userData?.user?.lastName
      ? userData?.email
      : ""
  } ${userData?.user?.inactive === true ? `(inactive)` : ""}`;
};
export const useUserEnum = (id?: any) => {
  const { groupUsers } = usePermissions();
  const { users: usersSelectedGroups, refetch: refetchGroupUsers } =
    useGetUsersInGroup(id);
  return [
    { id: null, title: "" },
    ...usersSelectedGroups.map(({ id, displayName }) => ({
      id,
      title: displayName,
    })),
  ];
};

export const ActionPlanTypeEnum = [
  { id: "", title: "" },
  { id: "Risk Evaluation", title: "Risk Evaluation" },
  { id: "Risk Treatment", title: "Risk Treatment" },
  { id: "Risk Analysis", title: "Risk Analysis" },
];

export const useGroupEnum = () => {
  const { groups } = usePermissions();
  return [
    { id: null, title: "" },
    ...groups.map(({ id, groupName }) => ({
      id,
      title: groupName,
    })),
  ];
};

export const getCompositeId = <
  T extends {
    compositeID?: number | null;
    compositeIDYear?: string | null;
  }
>(
  props: T
): string => {
  const compositeID = props?.compositeID;
  const compositeIDYear = props?.compositeIDYear;
  return (
    (compositeID != null &&
      compositeIDYear &&
      `${compositeIDYear?.substr(2)}-${_.padStart(
        compositeID.toString(),
        4,
        "0"
      )}`) ||
    ""
  );
};

export const getItemApprover = (item, itemApprover) => {
  const approverList = item?.[`${itemApprover}`]?.items;
  const approver = approverList?.slice(-1)?.[0]?.user ?? null;
  return formatUser(approver) ?? "Not Set";
};
